import {
    bindable,
    Container,
    customElement,
    inject,
    noView,
    ViewCompiler,
    ViewResources,
    ViewSlot
} from "aurelia-framework";
import {AbstractCell} from "./abstract-cell";
import * as _ from "lodash";

@customElement('sio-cell-collection')
@noView
@inject(ViewCompiler, ViewSlot, Container, ViewResources)
export class Collection extends AbstractCell {
    @bindable column;
    @bindable item;

    viewCompiler;
    viewSlot;
    container;
    viewResources;

    constructor(viewCompiler, viewSlot, container, viewResources) {
        super();
        this.viewCompiler = viewCompiler;
        this.viewSlot = viewSlot;
        this.container = container;
        this.viewResources = viewResources;
        this._defaultOptions['separator'] = ', ';
        this._defaultOptions['entryTemplate'] = '${ entry }';
    }

    attached() {
        // Trigger the attach for the slot and its children.
        this.viewSlot.attached();
    }

    bind(bindingContext) {
        this.bindingContext = bindingContext;
        if (_.has(this.item, this.column.property)) {
            this.bindingContext.value = _.get(this.item, this.column.property);
        }

        let value = this.getValue();

        //For embed fields, which are not many
        if (_.isObject(value) && !_.isArray(value)) {
            value = [value];
        }

        this.value = value ?? [];

        this.options = Object.assign({}, this._defaultOptions, this.column.options);

        this.bindingContext.options = this.options;
        this.bindingContext.value = this.value;

        if (this.view) {

            this.view.unbind();
            this.viewSlot.remove(this.view, true, true);
        }

        const viewFactory = this.viewCompiler.compile(
            this.options.template ?? `<template><div class="${this.options.separator !== '<br>' ? 'list-without-space' : ''}"><template
                repeat.for="entry of value">${this.options.entryTemplate}<span
                if.bind="!$last" class="separator">${this.options.separator}</span></template></template></div></template>
            `, this.viewResources);
        this.view = viewFactory.create(this.container);
        this.view.bind(bindingContext);
        this.viewSlot.add(this.view);
    }

    itemChanged() {
        this.bind(this.bindingContext);
    }
}
